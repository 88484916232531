<template>
  <div>
     <!-- 快递单设置弹窗 -->
    <template>
      <my-dialog-2
        :centerDialogVisible="dialogExpressVisible"
        @updateVisible="updateExpressVisible"
        @openDialog="handleOpenExpressDialog"
        @print="doPrintExpress"
      >
        <div class="express-dialog">
          <el-tabs
            v-model="expressTemplate"
            type="border-card"
            :before-leave="beforeLeave"
          >
            <el-tab-pane
              style="width: 1080px; height: 560px"
              v-for="(item, index) in expressTemplateList"
              :key="index"
              :label="item.express_name"
              :name="index + ''"
            >
              <div class="express-pro-box">
                <!-- 快递模板预览区 -->
                <!-- <iframe :id="'00' + index" width="800px" height="560px">
                  <p>当前浏览器不支持iframe标签</p>
                </iframe> -->
                <div class="preview-express-box">
                  <img style="width: 443px;" :src="expressTemplateList[activeExpressIndex].image_url" >
                </div>
                <!-- 属性编辑区 -->
                <div class="ExpressAttrBox">
                  <!-- <span class="ExpressAttrTitle">设置发货网点</span>
                  <div>
                    使用淘宝账号授权 
                    <a style="color: #3165ce" :href="TaobaoAuthURL" target="__b">新增店铺授权</a>
                    <div @click="GetWaybillSearch" class="RefreshAuthorization">授权后点我刷新</div>
                  </div> -->
                  <div>
                    <!-- <div class="ExpressAttrShopName">
                      {{get_shop_info.shop_name}}
                      <span @click="CancelAuth" class="DeleteShop">删除账号</span>
                    </div> -->
                    <template v-if="WaybillSite.length!=0">
                      <template v-for="(item,index) in WaybillSite">
                        <div :key="'WaybillSite'+index">
                          <div class="WaybillSiteAddress">{{item.province}}{{item.city}}{{item.district}}({{item.branch_code}})</div>
                          <div class="WaybillSiteNumber">可用单号{{item.quantity}}/已用单号{{item.allocated_quantity}}</div>
                          <div class="WaybillSiteInfoBox">
                            <el-radio @change="WaybillSiteInfoItemChange" v-model="WaybillSiteInfoItemSelected" class="WaybillSiteInfoItem" :label="item.id" >
                              <div class="WaybillSiteInfoItemRight">
                                <div class="WaybillSiteInfoItemText">{{item.province}}{{item.city}}{{item.district}}{{item.detail}}</div>
                                <div v-if="item.is_default" class="WaybillSiteInfoItemTips">使用中</div>
                              </div>
                            </el-radio>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div>
                        <div class="ExpressAttrShopOpenServiceTips">当前店铺尚未开通此快递的快手电子面单服务</div>
                        <a href="https://s.kwaixiaodian.com/zone/supply/express/electronic-sheet" target="_blank" class="ExpressAttrShopOpenServiceLink">申请开通</a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="express-tab-btn-box">
            <!-- <div @click="openAddExpressVisible" class="btn-container">
              <i class="el-icon-plus"></i>
              <el-button type="text" size="mini" class="lable-btn">添加模板</el-button>
            </div> -->
            <div @click="modifyExpressTemplateSequence" class="btn-container">
              <el-button type="text" size="mini" class="lable-btn">调整顺序</el-button>
            </div>
            <!-- <div @click="ExpressGlobalSettings" class="btn-container">
              <el-button type="text" size="mini" class="lable-btn">全局设置</el-button>
            </div> -->
          </div>
          <div class="modifyExpressTemplateSequenceBox" v-if="expressTemplateListTemp">
            <div class="ExpressTemplateModifySequenceBox">
              <div v-for="(item,index) in expressTemplateListTemp" :key="index"  class="ExpressTemplateModifySequenceLabel" :style="{backgroundColor:SelectedExpressTemplateModifySequenceLabel==index?'#fff':''}">
                <template v-if="SelectedExpressTemplateModifySequenceLabel==index">
                  <div @click="ExpressTemplateModifySequenceLabelBtn(index,index-1)" class="ExpressTemplateModifySequenceLabelLeft iconfont icon-arrow-left-bold"></div>
                  <div @click="ExpressTemplateModifySequenceLabelBtn(index,index+1)" class="ExpressTemplateModifySequenceLabelRight iconfont icon-arrow-right-bold"></div>
                  <!-- <div @click="ExpressTemplateModifySequenceLabelDelete(index)" class="ExpressTemplateModifySequenceLabelDelete iconfont icon-close-bold"></div> -->
                </template>
                <div @click="SelectedExpressTemplateModifySequenceLabelFN(index)" class="ExpressTemplateModifySequenceLabelText" :title="item.express_name">{{item.express_name}}</div>
              </div>
            </div>
            <div class="ExpressTemplateSaveSequenceBox">
              <el-button type="warning" size="mini" class="ExpressTemplateSaveSequenceBtn" @click="ExpressTemplateSaveFN">保存修改</el-button>
              <el-button type="info" size="mini" class="ExpressTemplateSaveSequenceBtn" @click="ExpressTemplateCancelFN">取消</el-button>
            </div>
          </div>
        </div>
      </my-dialog-2>
      <!-- 添加模板弹窗 -->
      <my-dialog-4
        :dialogTitle="'快递单'"
        :centerDialogVisible="dialogAddExpressTemplateVisible"
        @updateVisible="updateAddExpressVisible"
        @resetPopupData="updateAddExpressVisible(false)"
        @submitPopupData="saveAddExpressDialog"
      >
        <template slot="content">
          <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
            <span>添加快递公司</span>
          </div>
          <div class="content-subcontent" style="padding: 5px 0">
            <el-select v-model="addExpressId" placeholder="快递公司" size="mini">
              <el-option
                v-for="(item, index) in expressList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </template>
      </my-dialog-4>
      <!-- 全局设置 -->
      <my-dialog-5
        :dialogTitle="'快递单'"
        :centerDialogVisible="dialogExpressGlobalSettingsVisible"
        @updateVisible="updateExpressGlobalSettingsVisible"
        @resetPopupData="updateExpressGlobalSettingsVisible(false)"
        @submitPopupData="saveExpressGlobalSettingsDialog"
      >
        <template slot="content">
          <div class="ExpressGlobalSettingsBox">
            <div class="ExpressGlobalSettingsBoxLeft">
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">1</span>
                <span style="margin-left:10px;">快递单设置</span>
              </div>
              <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">默认快递</div>
                  <el-select v-model="addExpressId" placeholder="快递公司" size="mini">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">现有快递</div>
                  <div class="ExpressGlobalSettingsItemAllSetKDList">
                    <div class="ExpressGlobalSettingsItemAllSetKDListItem">
                      快手中通一联单
                      <i class="iconfont icon-close-bold ExpressGlobalSettingsItemAllSetKDListItemClose"></i>
                    </div>
                  </div>
                </div>
                <div @click="ExpressGlobalSettingsItemAllSetKDListItemAddExpress" class="ExpressGlobalSettingsItemAllSetKDListItemAddExpress">+ 添加快递单</div>
              </div>
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">2</span>
                <span style="margin-left:10px;">默认字体字号</span>
              </div>
              <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">默认字体</div>
                  <el-select v-model="ExpressGlobalSettingsFontFamily" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">默认字号</div>
                  <el-select v-model="ExpressGlobalSettingsFontSize" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select v-model="ExpressGlobalSettingsFontWeight" size="mini" style="width:80px;margin-left:20px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <div class="ExpressGlobalSettingsItemTips">注意：默认字号或加粗将影响所有模版，太大或太小都可能导致打印不全，请谨慎调试</div>
                </div>
              </div>
            </div>
            <div class="ExpressGlobalSettingsBoxRight">
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">3</span>
                <span style="margin-left:10px;">打印整体缩放</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span>横向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="ExpressGlobalSettingsScaleZoom_Transverse" :show-tooltip="false"></el-slider>
                <span>{{ExpressGlobalSettingsScaleZoom_Transverse}}%</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span>纵向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="ExpressGlobalSettingsScaleZoom_Longitudinal" :show-tooltip="false"></el-slider>
                <span>{{ExpressGlobalSettingsScaleZoom_Longitudinal}}%</span>
              </div>
            </div>
          </div>
        </template>
      </my-dialog-5>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

import myDialog2 from "@/UI/myDialog2";
import myDialog4 from "@/UI/myDialog4";
import myDialog5 from "@/UI/myDialog5";

export default {
  components: {
    myDialog2,
    myDialog4,
    myDialog5,  
  },
  data() {
    return {
      // 默认的渲染窗口
      activeExpressIndex: "0",
      dialogAddExpressTemplateVisible: false,
      dialogExpressGlobalSettingsVisible: false,
      ExpressGlobalSettingsFontFamily:"宋体",
      ExpressGlobalSettingsFontSize:"14",
      ExpressGlobalSettingsFontWeight:"加粗",
      ExpressGlobalSettingsScaleZoom_Transverse: 50, // 快递单横向缩放
      ExpressGlobalSettingsScaleZoom_Longitudinal: 50, // 快递单纵向缩放
      expressTemplateListTemp: null,
      SelectedExpressTemplateModifySequenceLabel:0,
      WaybillSiteInfoItemSelected: "", // 默认选中快递网点索引
    };
  },
  computed: { 
    ...mapState({
      expressTemplateList: (state) => state.print.expressTemplateList,
      expressList: (state) => state.print.expressList,
      TaobaoAuthURL: (state)=> state.batchprint.TaobaoAuthURL,
      get_shop_info: (state)=> state.setting.get_shop_info.data,
      WaybillSite: (state)=> state.batchprint.WaybillSite,
      }),
      addExpressId: {
        get() {
          return this.$store.state.print.addExpressId;
        },
        set(val) {
          this.$store.commit("print/SET_ADD_EXPRESS_ID", val);
        },
      },
      expressTemplate: {
        get() {
          return this.$store.state.batchprint.expressTemplate;
        },
        set(val) {
          this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val+'');
        }
      },
      dialogExpressVisible:{
        get() {
          return this.$store.state.batchprint.dialogExpressVisible;
        },
      }
  },
  methods: {
    showExpressDialog() {
      this.$store.dispatch('print/get_express_template');
    }, 
    // 弹窗渲染完成加载第一个模板
    handleOpenExpressDialog() {
      this.beforeLeave(this.activeExpressIndex)
    },
    updateExpressVisible(val) {
      this.$store.commit('batchprint/SAVE',{dialogExpressVisible:val})
    },
    // tab切换时提前触发的函数
    beforeLeave(activeIndex) {
      this.activeExpressIndex = activeIndex;
      this.GetWaybillSearch()
    },
    // 弹出添加快递模板弹窗
    openAddExpressVisible(){
      // 注意顺序
      this.updateExpressGlobalSettingsVisible(false)
      this.updateExpressVisible(false)
      this.dialogAddExpressTemplateVisible = true;
    },
    // 添加快递模板弹窗
    updateAddExpressVisible(val) {
      this.showExpressDialog()
      this.dialogAddExpressTemplateVisible = val;
    },
    saveAddExpressDialog() {
      this.$store.dispatch("print/add_express_template");
      this.dialogAddExpressTemplateVisible = false;
    },
      // 修改订单模板顺序
    modifyExpressTemplateSequence(){
      this.SelectedExpressTemplateModifySequenceLabel = this.expressTemplate
      this.expressTemplateListTemp = JSON.parse(JSON.stringify(this.expressTemplateList))
    },
    // 保存订单模板顺序的修改
    ExpressTemplateSaveFN(){
      let id = []
      this.expressTemplateListTemp.map(item=>{
        id.push(item.id)
      })
      this.$store.dispatch("batchprint/UpdateSortTemplate",{id});

      this.$store.commit("print/SET_EXPRESS_TEMPLATE_LIST",this.expressTemplateListTemp);
      this.ExpressTemplateCancelFN()
      this.expressTemplate = this.SelectedExpressTemplateModifySequenceLabel
    },
    // 取消修改
    ExpressTemplateCancelFN(){
      this.expressTemplateListTemp=null
    },
    // 选择要修改顺序的快递
    SelectedExpressTemplateModifySequenceLabelFN(index){
      this.SelectedExpressTemplateModifySequenceLabel = index
    },
    // 修改顺序
    ExpressTemplateModifySequenceLabelBtn(old_index,new_index){
      if(new_index<0||new_index>=this.expressTemplateListTemp.length )return false;
      let temp_list = JSON.parse(JSON.stringify(this.expressTemplateListTemp))
      let temp_Data = this.expressTemplateListTemp[old_index]
      temp_list[old_index] = temp_list[new_index]
      temp_list[new_index] = temp_Data
      this.expressTemplateListTemp = temp_list
      this.SelectedExpressTemplateModifySequenceLabelFN(new_index)
    },
    // 删除快递
    ExpressTemplateModifySequenceLabelDelete(index){
      let temp_list = this.expressTemplateListTemp.filter((n,m)=>{
        return index != m
      })
      if(this.SelectedExpressTemplateModifySequenceLabel >= temp_list.length){
        this.SelectedExpressTemplateModifySequenceLabel = temp_list.length-1
      }
      this.expressTemplateListTemp = temp_list
    },
    // 快递模板全局设置
    ExpressGlobalSettings(){
      this.updateExpressVisible(false)
      this.dialogExpressGlobalSettingsVisible = true
    },
    updateExpressGlobalSettingsVisible(val){
      this.showExpressDialog()
      this.dialogExpressGlobalSettingsVisible = val
    },
    saveExpressGlobalSettingsDialog(){
      // this.$store.dispatch("");
      this.dialogExpressGlobalSettingsVisible = false
    },
    ExpressGlobalSettingsItemAllSetKDListItemAddExpress(){
      this.openAddExpressVisible()
    },
  
    // 打印函数
    doPrintExpress() {
      this.$message.error("请使用下方打印按钮进行打印！");
    },
      // 获取网点
    GetWaybillSearch(){
      this.$store.dispatch("batchprint/GetWaybillSearch",{cp_code: this.expressTemplateList[this.activeExpressIndex].company_no }).then(res=>{
        if(res.data){
          res.data.map(item=>{
            if(item.is_default){
              this.WaybillSiteInfoItemSelected = item.id
            }
          })
        }
      });
    },
    // 修改使用网点
    WaybillSiteInfoItemChange(val){
      this.WaybillSite.map(item=>{
        if(item.id == this.WaybillSiteInfoItemSelected){
          item.is_default = 1
        }
      })
      this.$store.dispatch("batchprint/set_default_branch",{id: this.WaybillSiteInfoItemSelected})
    },
    CancelAuth(){
      this.$store.dispatch("batchprint/CancelAuth").then(res=>{
        this.GetWaybillSearch() // 取消授权后重新获取网点信息
      })
    }                                                  
  }
};
</script>

<style lang="less" scoped>
.express-pro-box{
  display: flex;
}

  // 重写快递单设置标签栏样式
/deep/ .express-dialog .el-tabs__header{
  width: 500px;
  border-bottom: none;
}
/deep/ .express-dialog .el-tabs__nav-prev{
  background: #333333;
  font-size: 12px;
  width: 18px;
  text-align: center;
  border-radius: 5px 0px 5px 0px;
}
/deep/ .express-dialog .el-tabs__nav-next{
  background: #333333;
  font-size: 12px;
  width: 18px;
  text-align: center;
  border-radius: 0px 5px 0px 5px;
}
.express-tab-btn-box{
  position: absolute;
  top: -40px;
  height: 40px;
  left: 510px;
  display: flex;
  background-color: #333;
  border-radius: 5px 5px 0 0;
}
.btn-container{
  margin: auto 10px;
  position: relative;
}
.btn-container:not(:first-child)::before{
  content: "|";
  position: absolute;
  left: -10px;
}
.modifyExpressTemplateSequenceBox{
  position: absolute;
  top: -40px;
  background-color: #4f4f4f;
  z-index: 3;
  width: 1080px;
}

.ExpressTemplateSaveSequenceBox{
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  width: 130px;
}
.ExpressTemplateSaveSequenceBtn{
  width: 80px;
  margin: 5px 0px;
}
.ExpressTemplateModifySequenceBox{
  font-size: 12px;
  padding: 8px 5px;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  width: 950px;
  min-height: 100px;
  background-color: #333;
  color: #ebb563;
}
.ExpressTemplateModifySequenceLabel{
  height: 32px;
  line-height: 32px;
  margin: 5px 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.ExpressTemplateModifySequenceLabelLeft{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 5px 0px 0px 5px;
  left: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelRight{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 0px 5px 5px 0px;
  right: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelText{
  height: 100%;
  padding: 0px 20px;
}
.ExpressTemplateModifySequenceLabelDelete{
  position: absolute;
  right: 0px;
  color: #999999;
  font-weight: bold;
  width: 20px;
  font-size: 12px;
  top: 1px;
}
.ExpressGlobalSettingsBox{
  // display: flex;
  height: 100%;
}
.ExpressGlobalSettingsBoxLeft{
  height: 430px;
  // height: 475px;
  // width: 390px;
  // border-right: 1px solid #6b6e76;
}
.ExpressGlobalSettingsLabel{
  margin-bottom: 14px;
  padding-left: 42px;
}
.ExpressGlobalSettingsItemName{
  display: inline-block;
  margin-right: 20px;
}

.ExpressAttrBox{
  flex: 1;
  padding: 30px 20px 0;
  background: #4e5159;
}
.ExpressAttrTitle{
  color: #eb9e26;
  font-size: 12px;
  line-height: 32.94px;
  margin: 10px 0px;
}

.RefreshAuthorization{
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  background: #f6891e;
}
.ExpressAttrShopName{
  border-bottom: 1px solid #6e7178;
  padding: 0 0 8px;
  color: #fff;
  margin: 20px 0 4px;
}

// 删除账号
.DeleteShop{
  color: #bdcfde;
  font-size: 14;
  text-decoration: underline;
  cursor: pointer;
}

.WaybillSiteAddress{
  margin-top: 10px;
  color: #9c9a9c;
}
.WaybillSiteNumber{
  margin-top: 10px;
  color: #adaead;
}
.WaybillSiteInfoBox{
  margin-top: 10px;
  background: #394142;
  border: #313839;
  border-radius: 5px;
  padding: 10px;
}
.WaybillSiteInfoItem{
  display: flex;
}
.WaybillSiteInfoItemRight{
  width: 190px;
  line-height: 1.5;
}
.WaybillSiteInfoItemText{
  word-break:break-all;
  white-space:pre-wrap;
  color: #9c9a9c;
}
.WaybillSiteInfoItemTips{
  color: #00ba9c;
}
.ExpressGlobalSettingsBoxRight{
  padding-left: 28px;
  width: 390px;
}
.ExpressGlobalSettingsZoomBox{
  display:flex;
  align-items: center;
  padding-left: 42px;
}
.ExpressAttrBox{
  flex: 1;
  padding: 30px 20px 0;
  background: #4e5159;
}
.ExpressAttrTitle{
  color: #eb9e26;
  font-size: 12px;
  line-height: 32.94px;
  margin: 10px 0px;
}
.ExpressAttrShopName{
  border-bottom: 1px solid #6e7178;
  padding: 0 0 8px;
  color: #fff;
  margin: 20px 0 4px;
}
.ExpressAttrShopOpenServiceTips{
  margin-top: 10px;
  color: #adadad;
  font-size: 12px;
}
.ExpressAttrShopOpenServiceLink{
  margin: 10px 0px;
  color: #b9cede;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.ExpressGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #fff;
}
.preview-express-box{
  width:800px;height:560px; overflow: auto;padding: 20px; box-sizing: border-box;
}
.preview-express-box img{
  border: 1px solid #eee;
}

.ExpressGlobalSettingsItemAllSetKDList{
  height: 120px;
  overflow-y: auto;
  padding-left: 4px;
  width: 250px;
  display: inline-block;
  vertical-align: top;
}
.ExpressGlobalSettingsItemAllSetKDListItem{
  background: #eee;
  border-radius: 2px;
  color: #425b6d;
  float: left;
  line-height: 20px;
  margin: 0 10px 10px 0;
  min-height: 20px;
  padding: 0 14px 0 10px;
  position: relative;
}
.ExpressGlobalSettingsItemAllSetKDListItemClose{
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 2px;
}
.ExpressGlobalSettingsItemAllSetKDListItemAddExpress{
  cursor: pointer;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 4px 0 0 112px;
  padding-left: 18px;
  text-decoration: none;
  width: 80px;
  border-radius: 20px;
  background-color: #f39f27;
}


</style>
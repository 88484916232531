<template>
   <el-dialog 
   class="dialog-1" 
   :title="dialogTitle"
   :width="popupWidth"
   :visible.sync="visible">
      <slot>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4}"
          placeholder="请输入内容"
          v-model="remark">
        </el-input>
        <el-row style="margin-top: 10px;">
          <el-radio-group v-model="remarkIcon" size="small">
            <el-radio-button label="1">红旗</el-radio-button>
            <el-radio-button label="2">蓝旗</el-radio-button>
            <el-radio-button label="3">绿旗</el-radio-button>
            <el-radio-button label="4">黄旗</el-radio-button>
          </el-radio-group>
        </el-row>
      </slot>
      <span slot="footer" class="dialog-1-footer">
        <el-button type="primary" size="mini" @click="Save">保存修改</el-button>
        <el-button size="mini" @click="Cancel">取消</el-button>
      </span>
    </el-dialog>
</template>

<script>

export default {
    name: "myDialog1",
    props: {
      // 弹窗标题
      dialogTitle: {
        type: String,
        default: "标题"
      },
      // 弹窗隐藏参数
      centerDialogVisible: {
        type: Boolean,
        default() {
          return false;
        }
      },
      // 弹窗默认宽度
      popupWidth: {
        type: String,
        default() {
          return "420px";
        }
      },
    },
    data() {
      return {
        remarkIcon: ''
      }
    },
    computed: {
      // 计算弹窗是否隐藏
      visible: {
        // 获取初始值centerDialogVisible
        get() {
          return this.centerDialogVisible;
        },
        // 重新计算visible值
        set(val) { 
          this.$emit("updateVisible", val);
        }
      },
      remark: {
        get() {
          return this.$store.state.batchprint.remarkInfo.memo;
        },
        set(val) { 
          this.$store.commit('batchprint/SET_REMARK_MEMO', val);
        }
      }
    },
    methods: {
      // 取消按钮函数
      Cancel() {
        this.$emit("resetPopupData");
        this.remarkIcon = ''
      },
      // 保存修改按钮函数
      Save() {
        if ( this.remarkIcon == ''){
          this.$message.error("请选择旗子！");
          return;
        }
        else {
          // this.$store.commit('batchprint/SET_REMARK_MEMO', this.remark);
          this.$store.commit('batchprint/SET_REMARK_REMARKICON', this.remarkIcon);
          this.$emit("submitPopupData");
          this.remarkIcon = ''
        }
      }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  border: 10px solid #9E9E9E;
  box-sizing: content-box;
  overflow: hidden;
  background: #fff;
}
/deep/ .el-dialog__title {
    display: block;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 1000;
}
/deep/ .el-dialog__header {
  box-sizing: border-box;
  padding: 0;
  width: auto;
  border-top: 10px solid #F6881E;
  border-bottom: 2px solid #E6E6E6;
}
/deep/ .el-dialog__headerbtn {
  position: absolute;
  top: 18px;
  right: 5px;
  height: auto;
  width: auto;
  background: none;
  border-radius: 0;
  font-size: 17px;
}
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #000;
}
/deep/ .el-dialog__body {
  padding: 10px;
}
/deep/ .el-dialog__footer {
  padding: 10px;
  background-color: #F3F3F3;
}
</style>